import React from 'react'
import './Golas.css'
import Goals from '../../../../assets/Goals.png'
const Golas = () => {
  return (
    <div className='GOALS' id='our-aim'>
     <div className='main-GOALS'>
      <div className='texts-GOALS-main'>
          <div className='eng-GOALS-main'>
          <div className='title-main'>
            <hr className='hr1'/>
            <h1 className='small-title'>
            Association’s goals
            </h1>
          </div>
            <h2 className='eng-title'>
            AIMS & VIEWS
            </h2>
            <p className='eng-desc'>
            Improve health and social conditions in Southern Lebanese villages. Prepare the youth, especially women, 
            to face livelihood challenges by increasing their productivity and improving their social and economic skills.
            </p>
          </div>
        <div className='arabic-GOALS-main'>
            <div className='arabic-title-main'>
                        <hr className='hr2'/>
                        <h1 className='arabic-small-title'>
                        أهدافُ الجَمعية                       
                         </h1>
                    </div>
           <h2 className='arabic-title'>
           الأهداف ووجهات النظر
           </h2>
            <p className='arabic-desc'>
            لمساهمة في تحسين الوضع الصحي والاجتماعي في قرى جنوب لبنان وتأهيل العناصر الشابة، خاصة النساء، وتطوير قدراتهم المهنية والانتاجية والاقتصادية
            </p>
                <a href='/goals' className='more-link'  id='moreGoals'>
                  more / المزيد
                  </a>
        </div>
      </div>
      <div className='Goals-image-container'>
              <img alt='Goals-image2' className='Goals-image'  src={Goals}/>
        </div>
   
    </div>
    </div>
  )
}

export default Golas