import React from 'react'
import './Aimsandgoals.css'
import GoalsImg1 from '../../assets/Goals1.png'
import Image2 from '../../assets/AIMS&VIEWS.jpg'
const Aimsandgoals = () => {
  return (
    <div className='Goals'>
      <div className='goals-section1'>

        <div className='goals-image1-container' >
          <img alt='goals-image1' className='goals-image1' src={GoalsImg1} />
        </div>

        <div className='goals-eng-main'>
          <div className='title-main'>
            <hr className='hr1' />
            <h1 className='small-title'> Association’s goals</h1>
          </div>
          <h1 className='eng-title'> AIMS & VIEWS</h1>
          <p className='eng-desc'>
            The Association is dedicated to improving health and social conditions in Southern Lebanese villages by providing essential services and support. Its focus includes enhancing healthcare access, promoting hygiene, and supporting families, with a special emphasis on women and children.
            <br />The Association also prepares the youth, particularly young women, to face livelihood challenges by offering training programs that boost productivity and develop social and economic skills. These programs include vocational training in areas such as nursing, computer literacy,  sewing and embroidery , which are designed to increase employment opportunities and financial independence.
            <br />By addressing both health and economic needs, the Association aims to empower individuals to improve their quality of life and contribute positively to their communities.
          </p>
        </div>
      </div>
      <div className='goals-section2'>
        <div className='goals-arabic-text-main'>
          <div className='arabic-title-main'>
            <hr className='hr2' />
            <h1 className='arabic-small-title'>
              أهدافُ الجَمعية
            </h1>
          </div>
          <h2 className='arabic-title'>
            الأهداف ووجهات النظر
          </h2>
          <p className='arabic-desc'>
            تلتزم الجمعية بتحسين الظروف الصحية والاجتماعية في قرى جنوب لبنان من خلال تقديم الخدمات الأساسية والدعم اللازم. تشمل المجالات الرئيسية التي تركز عليها تحسين الوصول إلى الرعاية الصحية، وتعزيز النظافة، وتقديم الدعم للأسر، مع اهتمام خاص بالنساء والأطفال.
            <br /> بالإضافة إلى ذلك، تعمل الجمعية على إعداد الشباب، وخاصة الشابات، لمواجهة تحديات المعيشة من خلال تقديم برامج تدريبية تهدف إلى تعزيز الإنتاجية وتطوير المهارات الاجتماعية والاقتصادية. تشمل هذه البرامج التدريب المهني في مجالات مثل الخياطة، والتطريز، ومحو الأمية الحاسوبية، بهدف زيادة فرص العمل وتعزيز الاستقلال المالي.
            <br /> من خلال معالجة الاحتياجات الصحية والاقتصادية، تسعى الجمعية إلى تمكين الأفراد لتحسين نوعية حياتهم والمساهمة بشكل إيجابي في مجتمعاتهم.
          </p>
        </div>

        <div className='goals-image2-container'>
          <img alt='aboutus-image2' className='goals-image2' src={Image2} />
        </div>
      </div>
    </div>
  )
}

export default Aimsandgoals