import React from 'react'
import './Vocationalschool.css'
import SchoolImg1 from '../../assets/VST1-a.png'
import SchoolImg2 from '../../assets/KfarhattaFinal.png'
import SchoolImg3 from '../../assets/Kfarhatta2Final.png'
import SchoolImg4 from '../../assets/Kfarhatta.png'



const Vocationalschool = () => {
  return (
    <div className='School'>
      <div className='school-section1'>

        <div className='school-image1-container' >
          <img alt='school-image1' className='school-image1' src={SchoolImg1} />
        </div>

        <div className='school-eng-main'>
          <div className='title-main'>
            <hr className='hr1' />
            <h1 className='small-title'>Vocational Training School</h1>
          </div>
          <h1 className='eng-title'>KFARHATA  NURSING SCHOOL</h1>
          <p className='eng-desc'>

            The project for a vocational training school, which would accommodate 120 students,
            became a reality later that year (2001) when the current JAWA president Mrs. Zeinab Osseiran donated a plot of land in Kfarhata for the specific purpose of building the facility.<br />
            The actual work on the school began in 2002. After having had architectural and engineering plans drawn up, the association was through its cash reserve fund (collected over the years) able to commence construction.
            <br />Since then work has continued subject to the availability of funds donated by JAWA supporters and benevolent donors. <br />
            These funds have been the fruits of not only fundraising events but also individual efforts for which the association is eternally grateful:
            <br />
            The school (as depicted on the cover) currently consists of the following:
            <br />
            <h4 className='list-title'>Ground floor:</h4>
            Administrative offices, cafeteria, covered open communal space and accommodation for a resident janitor
            <br />
            <h4 className="list-title">First floor:</h4>
            Administrative offices, teachers’ common room, six classrooms, laboratory and toilets/washrooms
            <br />
            <h4 className="list-title">Second floor:</h4>
            Teachers’ common room, six classrooms and toilets/washrooms
          </p>

          <h2 className='eng-sub-titles'> PRESENT STATUS OF THE SCHOOL</h2>
          <p className='eng-desc'>
            <h4 className="list-title">Ground floor and first floor:</h4>Completed, furnished and ready for immediate use.<br />
            <h4 className="list-title">Second floor:</h4> Requires procurement and installation of electrical wiring and fittings, procurement and installation of all sanitary fittings, painting and furnishing.
            For the school to function to its full potential this work needs to be completed as soon as possible.
          </p>
        </div>
      </div>
      <div className='school-section2'>
        <div className='school-arabic-text-main'>
          <div className='arabic-title-main'>
            <hr className='hr2' />
            <h1 className='arabic-small-title'>
              مَدْرَسَة كْفَرْحَتّى المِهَنيِّةَُ
            </h1>
          </div>
          <h1 className='arabic-title'>
            مدرسة التمريض كفرحتى
          </h1>
          <p className='arabic-desc'>
            بدأ مشروع انشاء مدرسة مهنية، تستوعب 120 تلميذا، يصبح حقيقة عندما تبرعت الرئيسة الحالية للجمعية، السيدة زينب عسيران في السنة نفسها (2001)، بقطعة أرض مناسبة لإقامة مشروع بناء المدرسة
            أصبح مشروع مدرسة التدريب المهني، الذي سيتسع لـ 120 طالباً، حقيقة في وقت لاحق من ذلك العام (2001) عندما تبرعت السيدة زينب عسيران، رئيسة جوا الحالية، بقطعة أرض في كفرحاتا لغرض محدد وهو بناء المنشأة.<br /> بدأ العمل الفعلي على المدرسة في عام 2002. وبعد وضع المخططات المعمارية والهندسية، تمكنت الجمعية من خلال صندوق الاحتياطي النقدي (الذي تم جمعه على مر السنين) من بدء البناء. <br /> ومنذ ذلك الحين، استمر العمل حسب توفر الأموال التي يتبرع بها داعمو جوا والجهات الخيرية. <br /> كانت هذه الأموال ثمرة ليس فقط للفعاليات الخيرية ولكن أيضاً لجهود الأفراد التي تكن الجمعية لها امتناناً أبدياً: <br /> تتكون المدرسة حالياً (كما هو موضح على الغلاف) من التالي: <br />

            <h4 className='list-title'>الطابق الأرضي:</h4>
            مكاتب إدارية، كافتيريا، مساحة مجتمعية مفتوحة مغطاة، وسكن للبواب المقيم <br />
            <h4 className="list-title">الطابق الأول:</h4> مكاتب إدارية، غرفة مشتركة للمعلمين، ستة فصول دراسية، مختبر ومراحيض/حمامات <br />
            <h4 className="list-title">الطابق الثاني:</h4> غرفة مشتركة للمعلمين، ستة فصول دراسية ومراحيض/حمامات </p>
          <h2 className='arabic-sub-title'> الحالة الحالية للمدرسة</h2> <p className='arabic-desc'>
            <h4 className="list-title">الطابق الأرضي والأول:</h4> اكتمل، تم تأثيثه وهو جاهز للاستخدام الفوري.<br />
            <h4 className="list-title">الطابق الثاني:</h4>
            يحتاج إلى شراء وتركيب الأسلاك الكهربائية والتجهيزات، وشراء وتركيب جميع التجهيزات الصحية، الدهان والتأثيث. لكي تعمل المدرسة بكامل إمكانياتها، يجب إكمال هذا العمل في أقرب وقت ممكن.
          </p>
        </div>

        <div className='school-image2-container'>
          <img alt='school-image2-b' className='school-image2-b' src={SchoolImg4} />
          <img alt='school-image2-b' className='school-image2-b' src={SchoolImg3} />
          <img alt='school-image2-a' className='school-image2-a' src={SchoolImg2} />

        </div>
      </div>
    </div>
  )
}

export default Vocationalschool