import React from 'react'
import { useState } from 'react'
import { Email, Phone } from "@mui/icons-material";
import './Donations.css'
import TextField from '@mui/material/TextField'
import Donations1 from '../../../../assets/Donations-1.png'
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
const Donations = () => {

  const [errors, setErrors] = useState({});
  const [fullName, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [comments, setComments] = useState('');



  const serializeForm = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    const newErrors = {};


    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formObject['email'])) {
      newErrors['email'] = 'Please enter a valid email address';
    }


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);

      return;
    }

    setErrors({});
    // console.log(formObject);



    const serviceId = 'service_vl9wrfn';
    const templateId = 'template_lrkdqam';
    const templateId2 = 'template_7mgv6lo';
    const publicKey = 'HkS-sCucNVReLHOoU';

    emailjs.send(serviceId, templateId2, formObject, publicKey)
      .then(
        (response) => {
          // console.log("Email Sent To The User Successfully!", response);
          setName('');
          setPhone('');
          setEmail('');
          setAmount('');
          setComments('');
          setErrors({});
        }
      )
      .catch(
        (error) => {
          console.log('Error Sending Email To The User:', error);
        }
      );


    emailjs.send(serviceId, templateId, formObject, publicKey)
      .then(
        (response) => {
          // console.log("Email Sent Successfully To Jawa!", response);
          setName('');
          setPhone('');
          setEmail('');
          setAmount('');
          setComments('');
          setErrors({});
          toast.success('Thank you for your donation! Your message has been sent successfully.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      )
      .catch(
        (error) => {
          // console.log('Error Sending Email To Jawa:', error);
          toast.error('Oops! There was a problem sending your message. Please try again later.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );


  };



  const commonStyles = {
    '& .MuiInputLabel-root': {
      borderWidth: '0.5px',
      color: '#30793d',
      textAlign: 'center',
      width: '100%',
      display: 'flex',
      opacity: '0.4'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '0.5px',
        borderColor: '#30793d',
      },
      '&:hover fieldset': {
        borderWidth: '0.5px',
        borderColor: '#30793d',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '0.5px',
        borderColor: '#30793d',
      },
    },
    '& .MuiInputBase-root': {
      height: '56px',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      borderWidth: '0.5px',
      color: '#30793d',
    },
  };


  return (
    <div className='Donations' id='donations-block'>
      <div className='main-donations'>
        <div className='donations-titles'>

          <div className='donations-title-main'>
            <hr className='donations-hr1' />
            <h1 className='donations-small-title' >
              Donations
            </h1>
          </div>
          <h2 className='donations-text'>DONATIONS</h2>

        </div>
        <div className='donations-container'>

          <div className='donations-image-container'>
            <img className='donations-image' alt='' src={Donations1} />
          </div>

          <form onSubmit={serializeForm} className='donations-inputs'>
            <TextField
              sx={commonStyles}
              id='full-name'
              name='fullName'
              type='text'
              label='Full Name'
              autoComplete='name'
              value={fullName}
              onChange={
                (e) => setName(e.target.value)
              }
              required
            />
            <TextField
              sx={commonStyles}
              id="phone"
              name="phone"
              type="tel"
              label="Telephone"
              autoComplete="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              inputProps={{
                pattern: "\\+?[0-9]{7,16}",
                title: "Phone number should start with an optional '+' followed by 7 to 16 digits",
              }}
            />

            <TextField
              sx={commonStyles}
              id='email'
              name='email'
              type='email'
              label='E-mail'
              autoComplete='email'
              value={email}
              onChange={
                (e) => setEmail(e.target.value)
              }
              required
              error={!!errors['email']}
              helperText={errors['email']}
            />
            <TextField
              sx={commonStyles}
              id='donation-amount'
              name='amount'
              type='number'
              label='Donation Amount'
              autoComplete='off'
              value={amount}
              onChange={
                (e) => setAmount(e.target.value)
              }
              required
              inputProps={{
                min: '1',
                step: '1'
              }}

            />
            <TextField
              sx={{
                '& .MuiInputLabel-root': {
                  opacity: '0.4',
                  color: '#30793d',
                  '&.Mui-focused': {
                    color: '#30793d',
                  },
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#30793d',
                  },
                  '&:hover fieldset': {
                    borderColor: '#30793d',
                  },
                  '&.Mui-focused fieldset': {
                    borderWidth: '0.5px',
                    borderColor: '#30793d',
                  },
                },
                '& .MuiInputBase-root': {
                  height: 'auto',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  borderWidth: '0.5px',
                  color: '#30793d',
                },
              }}
              className='comments'
              id='comments'
              name='comments'
              type='text'
              label='Comments'
              multiline
              autoComplete='off'
              minRows={4}
              value={comments}
              onChange={
                (e) => setComments(e.target.value)
              }
            />

            <button className='submit-btn' value='Submit' type='submit'>
              Submit
            </button>
          </form>


          <div className='contact-info'>
            <h2 className='contact-us-eng-title'>CONTACT US</h2>
            <p className='jawa-email'><Email className='icons' sx={{ fontSize: 16 }} />&nbsp;<a href="mailto:nisaajabalamel@gmail.com" className='jawa-links'>nisaajabalamel@gmail.com</a></p>
            <div className='jawa-location-container'>

              <p className='jawa-location'> Jabal Amel Women Association <br />Tallet El Khayat <br /> Al Tabch Bldg, 2th Floor</p>

            </div>
            <div className='contacts-jawa'><a href='tel:+961 1 740 541' className='jawa-links'><Phone className='icons' sx={{ fontSize: 16 }} /> +961 1 740541</a><a href='tel:+961 3 518614' className='jawa-links'><Phone className='icons' sx={{ fontSize: 16 }} /> +961 3 518614</a></div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default Donations