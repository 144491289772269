import React from 'react'
import SharedRoutes from './SharedRoutes'
import Navbar from './Components/Navbar/Navbar'
import Footer from './Components/Footer/Footer'
import ScrollToTop from './Components/UpScroller/UpScroller'

const App = () => {
  return (
    <>
      <Navbar />
      <SharedRoutes />
      <ScrollToTop />
      <Footer />
    </>

  )
}

export default App