import React from 'react';
import './Overview.css';
import overviewImg1 from '../../../../assets/overview-1.png';
import overviewImg2 from '../../../../assets/overview-2.png';

const Overview = () => {
  return (
    <div className='main-overview' id='overview'>
      <div className='main-overview-content'>
        <div className='overview-image1-container'>
          <img alt='overview-image1' className='overview-image1' src={overviewImg1} />
        </div>

        <div className='overview-text'>

          <div className='overview-eng-main'>
          <div className='title-main'>
            <hr className='hr1' />
            <h1 className='small-title'>Overview</h1>
          </div>
            <h1 className='eng-title'>WELCOME TO OUR OFFICIAL WEBSITE</h1>
            <p className='eng-desc'>
              A welfare Lebanese NGO, license no. 72/ad, founded in 1970 under the leadership of Mrs. Dunia Mroueh Al-Munjed.
            </p>
          </div>
   
          <div className='overview-arabic-text-main'>
          <div className='arabic-title-main'>
              <hr className='hr2' />
              <h1 className='arabic-small-title'>نبَذَةٌ عامَة</h1>
            </div>
            <h1 className='arabic-title'>مرحبا بكم في موقعنا الرسمي
            </h1>
            <p className='arabic-desc'>
              جمعية لبنانية خيرية غير حكومية تأسست عام 1970 تحت علم وخبر 72/أد برئاسة مؤسستها السيدة دنيا مروة المنجد. ترأستها منذ سنة 1998 السيدة زينب عسيران سعيدي.
            </p>
          </div>
          <a
            href='/about'
            className='more-link'>
                  more / المزيد
                  </a>
        </div>

        <div className='overview-image2-container'>
          <img alt='overview-image2' className='overview-image2' src={overviewImg2} />
        </div>
      </div>
    </div>
  );
};

export default Overview;
