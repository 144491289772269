import React from 'react'
import './Activities.css'
import Activities1 from '../../../../assets/Activities-1.png'
import Activities2 from '../../../../assets/Activities-2.png'

const Activities = () => {
  return (
    <div className='main-activities' id='activities'>

      <div className='main-activities-content'>

        <div className='activities-image1-container'>
          <img alt='activities-image1' className='activities-image1' src={Activities1} />
        </div>

        <div className='activities-text' id='ActivitiesText' >

          <div className='activities-eng-main'>
            <div className='title-main'>
              <hr className='hr1' />
              <h1 className='small-title'>
                Activities
              </h1>
            </div>
            <h1 className='eng-title'>
              TARFILSAY HEALTH CENTER
            </h1>

            <p className='eng-desc'>
              Founded in 1970 in cooperation with the Ministry of Social Affairs, the Center provides, Tarfilsay and its neighboring village health services through medical check-ups as well as medication at symbolic prices.
              The Center offers as well regular training programs in the fields of hygene and social awareness.
            </p>
          </div>

          <div className='activities-arabic-text-main'>
            <div className='arabic-title-main'>
              <hr className='hr2' />
              <h1 className='arabic-small-title'>
                مَرْكَز طْرَفِلسَيه الصُحيّ
              </h1>
            </div>
            <h1 className='arabic-title'>
              مركز طرفلسيه الصحي
            </h1>
            <p className='arabic-desc'>
              تأسس المركز في عام 1970 بالتعاون مع وزارة الشؤون الاجتماعية، ويقدم خدمات صحية لبلدة طيرفلسيه والقرى المجاورة من خلال الفحوصات الطبية وتوفير الأدوية بأسعار رمزية.
              كما يقدم المركز برامج تدريبية منتظمة في مجالات النظافة والتوعية الاجتماعية.
            </p>
          </div>
          <a href='/activities' className='more-link' id='moreActivities'>
            more / المزيد
          </a>
        </div>

        <div className='activities-image2-container'>
          <img alt='overvie-image2' className='activities-image2' src={Activities2} />
        </div>

      </div>
    </div>
  )
}

export default Activities