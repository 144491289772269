import React from 'react'
import './Aboutus.css'
import aboutusImg1 from '../../assets/overview-1.png'
import aboutusImg2 from '../../assets/LebaneseCedars.jpg'
const Aboutus = () => {

  return (
    <div className='About-Us' id='aboutus'>
     <div className='aboutus-section1'>
      
        <div className='aboutus-image1-container' >
          <img alt='aboutus-image1' className='aboutus-image1' src={aboutusImg1} />
        </div>

          <div className='aboutus-eng-main'>
          <div className='title-main'>
            <hr className='hr1' />
            <h1 className='small-title'>Overview</h1>
          </div>
            <h1 className='eng-title'>WELCOME TO OUR OFFICIAL WEBSITE</h1>
            <p className='eng-desc'>
              A welfare Lebanese NGO, license no. 72/ad, founded in 1970 under the leadership of Mrs. Dunia Mroueh Al-Munjed.<br/>
              The organization, officially registered under license number 72/ad, is a prominent welfare NGO based in Lebanon. Founded in 1970, it was established under the visionary leadership of Mrs. Dunia Mroueh al-Munjed. Since 1998, the Association has been guided by the dedicated leadership of Mrs. Zeinab Osseiran Saidi, who has played a crucial role in advancing its mission and expanding its impact.
              <br/>The Association has earned notable recognition for its exceptional contributions to social welfare and community development. In honor of its tireless efforts and significant achievements, it was bestowed with the esteemed Lebanese Cedars Order. This prestigious award highlights the organization's commitment to improving the lives of those it serves.
              <br/>In addition to its accolades, the Association is an active member of the Lebanese NGO Group, a coalition that fosters collaboration and advocacy among civil society organizations in Lebanon. 
              <br/>The organization's headquarters are located in Beirut, serving as the central hub for its operations. Beyond the capital, it maintains several centers of activity throughout South Lebanon, where it engages in various programs and initiatives aimed at supporting and uplifting local communities.
              <br/>Through its extensive network and dedicated team, the Association continues to make a profound impact, addressing critical needs and promoting positive change across the region.

               </p>
          </div>

      </div>
      <div className='aboutus-section2'>
         <div className='aboutus-arabic-text-main'>
           <div className='arabic-title-main'>
              <hr className='hr2' />
              <h1 className='arabic-small-title'>نبَذَةٌ عامَة</h1>
            </div>
            <h1 className='arabic-title'>مرحبا بكم في موقعنا الرسمي
            </h1>
            <p className='arabic-desc'>جمعية لبنانية خيرية غير حكومية، رقم الترخيص 72/ع د، تأسست في عام 1970 تحت قيادة السيدة دنيا مروه المنجد. 
              <br/>  تم تسجيل الجمعية رسميًا تحت رقم الترخيص 72/ع د، وهي جمعية خيرية بارزة مقرها لبنان. تأسست في عام 1970 تحت القيادة الرؤية للسيدة دنيا مروه المنجد. منذ عام 1998، تقود الجمعية السيدة زينب عسيران سعيدي، التي لعبت دورًا حاسمًا في تعزيز مهمتها وتوسيع تأثيرها.
             <br/> حظيت الجمعية بتقدير كبير على مساهماتها الاستثنائية في مجال الرعاية الاجتماعية والتنمية المجتمعية. تكريمًا لجهودها الدؤوبة وإنجازاتها الكبيرة، تم منحها وسام الأرز اللبناني المرموق. يسلط هذا الوسام الرفيع الضوء على التزام الجمعية بتحسين حياة الأفراد الذين تخدمهم.
              <br/> إلى جانب جوائزها، تُعد الجمعية عضوًا نشطًا في مجموعة الجمعيات الأهلية اللبنانية، وهي ائتلاف يشجع على التعاون والمناصرة بين منظمات المجتمع المدني في لبنان.
               <br/> يقع المقر الرئيسي للجمعية في بيروت، ويعمل كمركز رئيسي لعملياتها. وبالإضافة إلى العاصمة، تحتفظ الجمعية بعدة مراكز نشاط في جنوب لبنان، حيث تنفذ برامج ومبادرات متنوعة تهدف إلى دعم وتمكين المجتمعات المحلية.
                <br/> من خلال شبكتها الواسعة وفريقها المتفاني، تواصل الجمعية إحداث تأثير عميق من خلال تلبية الاحتياجات الأساسية وتعزيز التغيير الإيجابي في جميع أنحاء المنطقة. </p>
          </div>
              
          <div className='aboutus-image2-container'>
              <img alt='aboutus-image2' className='aboutus-image2' src={aboutusImg2} />
            </div>
      </div>
      </div>
  )
}

export default Aboutus