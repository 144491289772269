import React from 'react'
import './Activites.css'
import ActivitiesImg1 from '../../assets/Activities-1.png'
import ActivitiesImg2 from '../../assets/Tarfelsay.png'
import ActivitiesImg3 from '../../assets/TarfilsayFinal.png'
import ActivitiesImg4 from '../../assets/BurjRahalFinal.png'
import ActivitiesImg5 from '../../assets/ComputerFinal.png'

const Activites = () => {
  return (
    <div className='Activities'>
      <div className='actvities-section1'>

        <div className='actvities-image1-container' >
          <img alt='actvities-image1' className='actvities-image1' src={ActivitiesImg1} />
        </div>

        <div className='actvities-eng-main'>
          <div className='title-main'>
            <hr className='hr1' />
            <h1 className='small-title'>Activities</h1>
          </div>
          <h1 className='eng-title'> TARFILSAY HEALTH CENTER</h1>
          <p className='eng-desc'>
            Founded in 1970 in cooperation with the Ministry of Social Affairs, the Center provides,
            Tarfilsay and its neighboring village health services through medical check-ups as well as medication at symbolic prices.
            <br />The Center offers as well regular training programs in the fields of hygene and social awareness.
          </p>

          <h1 className='eng-sub-titles'> Burj Rahal Dental Health Center January 2004</h1>
          <p className='eng-desc'>
            Established in January 2004, in cooperation with “Give the Child a Toy (NGO)” in order to increase dental health awareness and to treat dental and mouth diseases, particularly for children.
          </p>

          <h1 className='eng-sub-titles'>Computer Training Courses</h1>
          <p className='eng-desc'>
            This program was launched in 2000. The program involves a mobile (inter-village) computer school that offers a 6-week basic course in computer literacy for all ages.
            <br />The capacity of the program is 40 students at a time. By August 2004, it had been offered in Aita AShaab, Kfar-kila, Deir Mimas, Sela’a, Houla, Zawtar, Khirbit Silim, Majdel Silim, Deir Kifa, Qa’aquaiat Al- Jisr, Kosaibe, Shakra, Al-Qrayeh, Bara’shite, Tayrfilsay, Al-Taibih, Shiba’a, Kounine, Kfardounine and Kafra to over 1340 young women and men.
            <br />Amid enthusiastic welcome, participation in this program has improved the job opportunities for its rural graduates.
          </p>

          <h1 className='eng-sub-titles'>The NGO Activities between 1970 until 2000</h1>
          <p className='eng-desc'>

            Since its founding in 1970 until the year 2000, the Association established numerous training centers dedicated to empowering village women by teaching essential skills such as sewing, embroidery, crafts, weaving, tricot, and hairdressing.
            <br />These centers also provided regular courses focused on hygiene, housekeeping, cooking, and child-rearing, equipping women with valuable knowledge for their daily lives.
            <br />In 1972, the Association established the region's first Nursing School in Tibnin, South Lebanon, offering students a baccalaureate degree in nursing accredited by the Directorate for Vocational and Technical Training. Unfortunately, this institution was forced to close due to the Israeli invasion.
            <br />In addition to the nursing school, the Association established affiliated infirmaries within its social centers to provide free medical services and medications. First aid and civil training programs were offered in key southern cities such as Tyre, Zawtar al-Sharqiyah, and Deir Kifa.
            <br />At its headquarters, the Association organized French, English, and typewriting courses, and launched anti-illiteracy campaigns across South Lebanon. It also promoted cultural and educational advancement through sponsored lectures and orientation programs.
            <br />The Association's community centers were strategically located throughout South Lebanon in areas such as Kfarsir, Kfartinbit (Nabatiyeh District), Yareen, al-Abbassiyah, Tarfilsay (Tyre District), al-Babiliyah, Ansariyah, Zrariyah, Marwaniyah, Ankoun (Zahrani District), and Tibnin, Shakrah, Braashit, Yatter, and Kafra (Bint el-Jbeil District). Many of these centers became inactive as a result of the Israeli invasion, although some were developed in cooperation with the Ministry of Social Affairs.
            <br />In response to Israeli aggressions, the Association launched numerous humanitarian relief campaigns, supporting victims affected by the conflict. Additionally, every year during the holy month of Ramadan, the Association distributes food supplies to families in need, ensuring they have essential resources during this sacred time.

          </p>

        </div>
      </div>
      <div className='actvities-section2'>
        <div className='actvities-arabic-text-main'>
          <div className='arabic-title-main'>
            <hr className='hr2' />
            <h1 className='arabic-small-title'>
              مَرْكَز طْرَفِلسَيه الصُحيّ
            </h1>
          </div>
          <h1 className='arabic-title'>
            مركز طرفلسيه الصحي
            {/* مَرْكَز طْرَفِلسَيه الصُحيّ */}
          </h1>
          <p className='arabic-desc'> تأسس المركز في عام 1970 بالتعاون مع وزارة الشؤون الاجتماعية،
            ويقدم خدمات صحية لبلدة طيرفلسيه والقرى المجاورة من خلال الفحوصات الطبية وتوفير الأدوية بأسعار رمزية.
            <br />كما يقدم المركز برامج تدريبية منتظمة في مجالات النظافة والتوعية الاجتماعية.
          </p>
          <h1 className='arabic-sub-title'>مركز برج رحال لصحة الأسنان – يناير 2004</h1>
          <p className='arabic-desc'> تم إنشاء المركز في يناير 2004 بالتعاون مع منظمة "أعطِ الطفل لعبة" لزيادة الوعي بصحة الأسنان وعلاج أمراض الفم والأسنان، خاصة للأطفال. </p>
          <h1 className='arabic-sub-title'>دورات تدريبية في الحاسوب</h1>
          <p className='arabic-desc'> تم إطلاق هذا البرنامج في عام 2000. يشمل البرنامج مدرسة حاسوب متنقلة بين القرى تقدم دورة أساسية لمدة 6 أسابيع في محو الأمية الحاسوبية لجميع الأعمار.
            <br />تبلغ سعة البرنامج 40 طالبًا في المرة الواحدة. وبحلول أغسطس 2004، تم تقديم الدورة في عيتا الشعب، كفركلا، دير ميماس، سلعا، حولا، زوطر، خربة سلم، مجدل سلم، دير كيفا، قاعقعية الجسر، قسطيبة، شقرا، القريّة، براشيت، طيرفلسيه، الطيبة، شبعا، كوينين، كفردونين وكفرا لأكثر من 1340 شابًا وشابة.
            <br />وسط ترحيب حماسي، حسّن هذا البرنامج من فرص العمل لخريجيه في المناطق الريفية. </p>
          <h1 className='arabic-sub-title'>أنشطة الجمعية من 1970 حتى 2000</h1>
          <p className='arabic-desc'> منذ تأسيسها في عام 1970 وحتى عام 2000، أنشأت الجمعية العديد من مراكز التدريب المكرسة لتمكين النساء في القرى من خلال تعليمهن المهارات الأساسية مثل الخياطة، التطريز، الحرف اليدوية، حياكة التريكو، وتصفيف الشعر.
            <br />كما قدمت هذه المراكز دورات منتظمة تركز على النظافة، وإدارة المنازل، والطبخ، وتربية الأطفال، مما يزوّد النساء بمعرفة قيمة لحياتهن اليومية.
            <br />في عام 1972، أنشأت الجمعية أول مدرسة تمريض في المنطقة في تبنين، جنوب لبنان، حيث حصل الطلاب على شهادة البكالوريا في التمريض معتمدة من مديرية التعليم المهني والتقني. ولكن للأسف، تم إغلاق هذه المؤسسة بسبب الغزو الإسرائيلي.
            <br />بالإضافة إلى مدرسة التمريض، أنشأت الجمعية مستوصفات تابعة لمراكزها الاجتماعية لتقديم الخدمات الطبية والأدوية مجانًا. كما تم تقديم برامج تدريب على الإسعافات الأولية والخدمات المدنية في المدن الجنوبية الرئيسية مثل صور، زوطر الشرقية، ودير كيفا.
            <br />في مقرها الرئيسي، نظمت الجمعية دورات في اللغة الفرنسية والإنجليزية والكتابة على الآلة الكاتبة، وأطلقت حملات لمكافحة الأمية في جميع أنحاء جنوب لبنان. كما شجعت التقدم الثقافي والتعليمي من خلال المحاضرات والبرامج التوجيهية التي كانت تدعمها.
            <br />تمركزت مراكز الجمعية المجتمعية في جميع أنحاء جنوب لبنان في مناطق مثل كفرصير، كفرتنبيت (قضاء النبطية)، يارين، العباسية، طيرفلسيه (قضاء صور)، البابلية، أنصارية، زرارية، مروانية، عنقون (قضاء الزهراني)، وتبنين، شقرا، براشيت، ياطر، وكفرا (قضاء بنت جبيل). أصبح العديد من هذه المراكز غير نشط بسبب الغزو الإسرائيلي، ولكن بعضها تم تطويره بالتعاون مع وزارة الشؤون الاجتماعية.
            <br />ردًا على الاعتداءات الإسرائيلية، أطلقت الجمعية العديد من حملات الإغاثة الإنسانية لدعم المتضررين من النزاع. بالإضافة إلى ذلك، تقوم الجمعية كل عام خلال شهر رمضان المبارك بتوزيع المواد الغذائية على الأسر المحتاجة، مما يضمن لهم الموارد الأساسية خلال هذا الوقت المقدس.
          </p>
        </div>

        <div className='actvities-image2-container'>

          <img alt='actvities-image2-a' className='actvities-image2-a' src={ActivitiesImg2} />
          <img alt='actvities-image2-b' className='actvities-image2-b' src={ActivitiesImg3} />
          <img alt='actvities-image2-c' className='actvities-image2-c' src={ActivitiesImg4} />
          <img alt='actvities-image2-d' className='actvities-image2-d' src={ActivitiesImg5} />

        </div>
      </div>
    </div>
  )
}

export default Activites