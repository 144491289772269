import { Route, Routes } from "react-router-dom"
import Home from "./Pages/Home/Home"
import Aboutus from "./Pages/Aboutus/Aboutus"
import Activites from "./Pages/Activities/Activites"
import Aimsandgoals from "./Pages/Aimsandgoals/Aimsandgoals"
import Vocationalschool from "./Pages/Vocationalschool/Vocationalschool"



const SharedRoutes = () => {
  return (
    <Routes>
      <Route exact path="/*" element={<Home />} />
      <Route exact path="/about/*" element={<Aboutus/>}  />
      <Route exact path="/activities/*" element={<Activites />} />
      <Route exact path="/goals/*" element={<Aimsandgoals />} />
      <Route exact path="/school/*" element={<Vocationalschool />} />

    </Routes>
  )
}

export default SharedRoutes