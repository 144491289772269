import React from 'react'
import './Home.css'
import Hero from './Components/Hero/Hero'
import Overview from './Components/Overview/Overview'
import VST from './Components/VTS/VST'
import Activities from './Components/Activities/Activities'
import Golas from './Components/Goals/Golas'
import Donations from './Components/Donations/Donations'
const Home = () => {
  return (
   <div className='home'>
      <Hero />
      <Overview />
       <VST />
      <Activities />
      <Golas />
      <Donations/>
   
      </div>
  )
}

export default Home