import './Navbar.css'
import LogoJawa from '../../assets/Logo_Jawa.png'
import { CloseOutlined, Email, Menu, Phone } from "@mui/icons-material";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
  const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleNavbar = () => {
      setIsOpen(prevState => !prevState);
    };

    const navigate = useNavigate();

    const handleScrollToId = (id) => {
      navigate('/'); 
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); 
      setIsOpen(false);
    };
  return (
    <div className='main-NavBar'>
      
      <div className="nav-contactinfo-section">
        <div className="nav-contactinfo-container"><Email className='icons' sx={{fontSize: 20}}/> <a className="nav-contactinfo-text" href="mailto:nisaajabalamel@gmail.com">nisaajabalamel@gmail.com</a></div>
        <div  className="nav-contactinfo-container"><Phone className='icons' sx={{ fontSize: 20 }}/> <a className="nav-contactinfo-text"  href="tel:+961 1 740 541">+961 1 740 541</a></div>
      </div>
      
      <div className="nav-second-sec">
          <div className='jawa-logo'>
            <a href='/' className="logo-a"><img className="jawa-img" alt="Logo" src={LogoJawa}></img></a>
          </div>
          <div className="nav-bar">
          <a className="nav-links" href='/#' onClick={() => handleScrollToId('overview')} >Overview</a>
          <a className="nav-links" href='/#' onClick={() => handleScrollToId('vocational-school')}>Vocational School</a>
          <a className="nav-links" href='/#' onClick={() => handleScrollToId('activities')}>Activities</a>
          <a className="nav-links" href='/#' onClick={() => handleScrollToId('our-aim')}>Our Aim</a>
          <a className="nav-links" href='/#' onClick={() => handleScrollToId('donations-block')}>Donations</a>
            </div>
            <div className={`responsive-nav-bar ${isOpen ? 'open' : ''}`}>
            <div className='responsive-jawa-logo'>
            <a href='/' className="responsive-logo-a"><img className="responsive-jawa-img" alt="Logo" src={LogoJawa}></img></a>
          </div>
                  <a className="responsive-nav-links" href='/#' onClick={() => handleScrollToId('overview')}>Overview</a>
                  <a className="responsive-nav-links" href='/#' onClick={() => handleScrollToId('vocational-school')}>Vocational School</a>
                  <a className="responsive-nav-links" href='/#' onClick={() => handleScrollToId('activities')}>Activities</a>
                  <a className="responsive-nav-links" href='/#' onClick={() => handleScrollToId('our-aim')}>Our Aim</a>
                  <a className="responsive-nav-links" href='/#' onClick={() => handleScrollToId('donations-block')}>Donations</a>
                  <div className="responsive-social">
                    <div className="responsive-social-sec"><Email sx={{ fontSize: 18 }}/> <a className="responsive-social-sec-links" href="mailto:nisaajabalamel@gmail.com">nisaajabalamel@gmail.com</a></div>
                    <div className="responsive-social-sec"><Phone sx={{ fontSize: 18 }}/> <a className="responsive-social-sec-links"  href="tel:12345">+961 1 740 541</a></div>
                  </div>
            </div>
            <div className="nav-toggle" onClick={toggleNavbar}>
            {isOpen ? <CloseOutlined sx={{ fontSize: 30 }}/> : <Menu sx={{ fontSize: 30 }}/> }
          </div>
       </div>
    </div>
  )
}

export default Navbar