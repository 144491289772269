import React from 'react'
import LogoJawa from '../../assets/Logo_Jawa2.png'
import './Footer.css'
import in2info from '../../assets/in2infoLogo.png'
import { Facebook, Instagram } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';
const Footer = () => {

  const navigate = useNavigate();

  const handleScrollToId = (id) => {
    navigate('/'); 
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); 
  };
  return (
      <div className='main-footer' >
        <div className='footer-sec'>

            <a href='/' className="footer-logo-a"><img className="footer-jawa-img" alt="Logo" src={LogoJawa}></img></a>
        
          <div className="footer-menu">
                  <h2 className='footer-title'>LINKS</h2>
                  <a className="footer-links" href='/#' onClick={() => handleScrollToId('overview')} >Overview</a>
                  <a className="footer-links" href='/#' onClick={() => handleScrollToId('vocational-school')}>Vocational School</a>
                  <a className="footer-links" href='/#' onClick={() => handleScrollToId('activities')}>Activities</a>
                  <a className="footer-links" href='/#' onClick={() => handleScrollToId('our-aim')}>Our Aim</a>
                  <a className="footer-links" href='/#' onClick={() => handleScrollToId('donations-block')}>Donations</a>
                 
            </div>
            
                <div className='media-main'>
                    <h2 className='footer-title'>SOCIAL</h2>
                    <div className='media'>
                    <a href='https://www.facebook.com/people/Jabal-Amel-Women-Association/100068694997218/' className='media-links'>
                      <Facebook sx={{ fontSize: 30 }}/>
                      Facebook
                    </a>
                    <a href='/#'className="media-links">
                      <Instagram sx={{ fontSize: 30 }}/>
                      Instagram
                    </a>   
                    </div>
                  </div>
         </div>
         
            <div className='rights'>
             <span>
              © 2024 Jawa Jabal Amel Women's Association All rights reserved 
              </span>
              <span className='sitemanager'>SITE<b>MANAGER</b> V4.0
                 <a href='http://www.in2info.com/' target='blank' className='in2infoLink'> <img src={in2info} alt='In2Info' className='in2info'/> </a>
              </span>
            </div>
       </div>     
  )
}

export default Footer