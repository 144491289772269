import React from 'react'
import './Hero.css'
import JabalAmel from '../../../../assets/Hero_Section.png'
const Hero = () => {
  return (
    <div className='hero-section'>
      
      <div className='image-container'>
        <img src={JabalAmel} alt='JabalAmelImage' className='image'/>
        </div>

      <div className='texts'>
         <div className='eng-text'>
           <h1 className='hero-eng-title'>
            KNOWLEDGE AND ACTION
            </h1>
           <p className='eng-description'>
            Knowledge and action" are the essence of success and progress in life... 
            When knowledge is combined with action,it transforms into a powerful force that aids in achieving success.
            </p>
         </div>
         <p className='arabic-description'>
         
          علم وعمل هما جوهر النجاح والتقدم في الحياة
       
          ....عندما يتم دمج العلم بالعمل، يتحول العلم إلى قوة فعلية تساعد على تحقيق النجاح والتطور 
        
         </p>
      </div>

    </div>
  )
}

export default Hero