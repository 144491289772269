import React from 'react'
import './VST.css'
import VSTImg1 from '../../../../assets/VST1-a.png'
import VSTImg2 from '../../../../assets/VST2.png'
const VST = () => {
  return (
    <div className='VST' id='vocational-school'>
      <div className='main-VST'>
        <div className='texts-VST-main'>
          <div className='eng-VST-main'>
            <div className='title-main'>
              <hr className='hr1' />
              <h1 className='small-title'>
                Vocational Training School
              </h1>
            </div>
            <h2 className='eng-title'>
              KFARHATA NURSING SCHOOL
            </h2>
            <p className='eng-desc'>
              The project for a vocational training school, which would accommo-date 120 students,
              became a reality later that year (2001) when the current JAWA president Mrs. Zeinab
              Osseiran donated a plot of land in Kfarhata for the specific purpose of building the facility.
              The actual work on the school began in 2002. After having had archi...
            </p>
          </div>

          <div className='arabic-VST-main'>
            <div className='arabic-title-main'>
              <hr className='hr2' />
              <h1 className='arabic-small-title'>
                مَدْرَسَة كْفَرْحَتّى المِهَنيِّةَُ
              </h1>
            </div>
            <h2 className='arabic-title'>
              مدرسة التمريض  كفرحتى
            </h2>
            <p className='arabic-desc'>
              بدأ مشروع انشاء مدرسة مهنية، تستوعب 120 تلميذا، يصبح حقيقة عندما تبرعت الرئيسة الحالية للجمعية، السيدة زينب عسيران في السنة نفسها (2001)، بقطعة أرض مناسبة لإقامة مشروع بناء المدرسة
            </p>
            <a href='/school' className='more-link' id='moreVST'
            >
              more / المزيد
            </a>
          </div>
        </div>
        <div className='imagesSection'>
          <div className='VST-image1-container'>
            <img alt='VST-image1' className='VST-image1' src={VSTImg1} />
          </div>
          <div className='VST-image2-container'>
            <img alt='VST-image2' className='VST-image2' src={VSTImg2} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VST